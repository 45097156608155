.identity-confirmation-container {
  .header {
    background: var(--colors-main);

    .spinner {
      color: var(--colors-highlight);
    }
  }

  .checked-icon rect{
    fill: var(--colors-main);
    stroke: var(--colors-main);
  }

  @media screen and (max-height: 900px) {
    .identity-confirmation-content > div {
      gap: 24px;
    }

    .identity-confirmation-content > div div:has(button){
      margin-top: 0px;
    }
  }
}