@import '../../assets/variables';

.priority-list-question-container {
  position: relative;

  .draggable-container {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    .item-order-number {
      position: relative;
      width: 40px;

      .item-number {
        position: absolute;
        left: 13px;
        top: 10px;
        font-size: 14px;
        color: #999;
      }
    }

    .draggable {
      height: 50px;
      width: 300px;
      box-shadow: 0 5px 6px rgba(0, 0, 0, 0.08), 0 2px 3px rgba(0, 0, 0, 0.24), 0 2px 1px rgba(0, 0, 0, 0.2);
      border-radius: 4px;
      border-left: 20px solid var(--colors-highlight);
      background-color: var(--colors-backgroundLight);
      display: flex;
      align-items: center;
      color: var(--colors-fontDark);
      z-index: 100;
      padding-left: 10px;
      position: relative;
      cursor: all-scroll;

      @media (max-width: $phone-md-max) {
        width: 250px;
      }

      .editable {
        display: flex;
        align-items: center;

        .custom-item-title {
          margin: 0;
        }

        .custom-item-title-input {
          .MuiInputLabel-formControl {
            top: -5px;
          }
        }
      }

      .action-icon {
        font-size: 16px;
        color: gray;
        cursor: pointer;
        margin-left: 5px;
      }

      .item-text {
        text-align: left;
      }

      .drag-icon {
        position: absolute;
        left: -15px;
        top: 17px;
      }
    }
  }

  .add-item {
    text-align: left;
  }

  .arrows {
    display: flex;
    flex-direction: column;
    width: 40px;
    position: absolute;
    top: calc(50% - 70px);
    left: 400px;

    @media (max-width: $phone-sm-max) {
      display: none;
    }

    @media (max-width: $phone-md-max) {
      left: 340px;
    }

    .drag-direction-up {
      margin-bottom: 15px;

      .MuiFab-label {
        transform: rotate(-90deg);
      }
    }

    .drag-direction-down {
      .MuiFab-label {
        transform: rotate(90deg);
      }
    }

    .drag-direction-up, .drag-direction-down {
      width: 40px;
      height: 40px;
      color: var(--colors-fontLight);
    }

    .highlight {
      background-color: var(--colors-highlight);
    }
  }
}
