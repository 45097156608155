@import '../../assets/variables';

.single-line-text-question-container {
  text-align: left;

  .text-box {
    display: flex;
    align-items: center;

    .characters-left {
      opacity: 0.5;
      background-color: var(--colors-backgroundLight);
      border-radius: 20px;
      padding: 0 5px;
    }

    @media screen and (max-width: 600px){
      flex-direction: column;
      align-items: flex-start !important;
      gap: 10px !important;
    }
  }

  .remove-item {
    cursor: pointer;
    color: gray;
  }
}
