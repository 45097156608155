@import '../assets/variables';

.survey-submitted-container {
  flex-grow: 1;
  padding-top: 30px;
  text-align: center;

  @media (max-width: $phone-md-max) {
    flex-grow: unset;
  }

  .success-text {
    font-size: 40px;
    color: var(--colors-fontDark);
  }
}
