@import "../../assets/variables";

.matrix-single-select-question-container {
  .text-bold {
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
  }

  .matrix-mobile {
    .select-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 12px;

      .item-text {
        margin-left: 8px;
      }
    }

    .MuiInputLabel-formControl {
      left: 10px;
      top: -8px;
    }
    .MuiInputLabel-shrink {
      display: none;
    }
  }

  .matrix-desktop {
    min-width: 125%;
    margin-left: -12.5%;
    padding-bottom: 15px;
    text-align: left;

    // scroll width
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    justify-content: unset;

    .option-text, .options, .option-container {
      display: flex;
      flex: 0 0 auto;
    }
    .option-text, .option-container {
      flex-direction: column;
    }

    .option-text {
      width: 150px;
      justify-content: space-between;
      margin-top: 25px;

      @media (max-width: $screen-md-max) {
        width: 100px;
      }
    }

    .item-option {
      height: 25px;
      text-align: center;
    }

    .item-text {
      width: 100%;
    }

    .option-container {
      align-items: center;
      width: 150px;

      @media (max-width: $screen-md-max) {
        width: 100px;
      }
    }
  }
}
