@import '../../assets/variables';


  .controls {
    display: inline-flex;
    width: 70%;
    justify-content: space-between;
    margin-top: 10px;
    font-weight: bold;

    @media (max-width: $phone-md-max) {
      width: 100%;
    }

    .sum {
      background-color: var(--colors-backgroundLight);
      border-radius: 20px;
      padding: 2px 7px;
      align-self: center;
    }
  }

  .add-item {
    text-align: left;
  }
