@import '../assets/variables';

.multi-slider-single-picker-container{
  @media screen and (max-width: 600px){
    margin-top: 30px !important;
  }
}

.slider-container {
  width: 100%;
  padding-bottom: 16px;

  .alert-container{
    margin-top: 32px;
  }

  @media screen and (max-width: 600px){
    margin-top: 30px !important;
    padding: 0px !important;
  }

  @media (max-width: $screen-sm) {
    width: 100%;

    & > div{
      grid-template-columns: 1fr !important;
      
      .title-container{
        margin-bottom: -30px;

        .title{
          @media screen and (max-width: 600px){
            margin-left: -5px;
          }
        }
      }
    }
  }

  .MuiSlider-root {
    width: 100%;
    color: var(--colors-main);
  }

  .MuiSlider-marked {
    margin-bottom: 0px;
  }

  .MuiSlider-markLabel {
    color: var(--text-lightGrey);
  }

  .slider{
    max-width: 560px;
  }

  .title-container {
    display: flex;
    max-width: 200px;
    width: 100%;


    @media (max-width: $phone-md-max) {
      flex-direction: column;
      max-width: initial;
    }

    .title {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .slider-title {
        margin-bottom: 0;
        font-size: 15px;
        line-height: 20px;
        color: var(--text-mediumGrey);
        font-size: 15px;

        span{
          display: block;
          font-size: 14px;
          color: var(--text-lightGrey);
          width: 100%;
          word-break: break-word;
        }

        span:first-child{
          color: var(--text-mediumGrey);
          font-size: 15px;
        }
      }

      .action-icon {
        font-size: 16px;
        color: gray;
        cursor: pointer;
        margin-left: 5px;

        @media (max-width: $phone-md-max) {
          font-size: 20px;
          margin-left: 15px;
        }
      }

      .action-icon.edit-mode {
        margin: 10px 0 0 5px;
      }
    }
  }

  .title-container.error {
    .slider-title {
      margin-right: 10px;

      @media (max-width: $phone-md-max) {
        margin-bottom: 10px;
      }
    }
  }

  .min-max-text {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: -12px;
    width: 100%;

    span {
      font-size: 14px;
      line-height: 16px;
      color: var(--text-mediumGrey);
      margin: 0px -15px 0px -15px;
    }
  }
}
