@import '../../assets/variables';

.single-select-question-container {
  .text-buttons {
    .MuiChip-label {
      height: 100%;

      .MuiInputBase-root {
        color: inherit;
        margin: 5px 0 13px 0;
      }

      .MuiFormControl-root {
        text-transform: none;
      }

      .MuiInputLabel-root {
        top: -10px;
      }

      .MuiInputLabel-root.Mui-focused {
        top: 0;
      }
    }
  }

  .images {
    .image-option {
      &:hover {
        box-shadow: 0px 2px 6px 0px #7B7D8947;
      }

      @media (max-width: $phone-md-max) {
        width: 140px;
      }

      .image-text {
        color: var(--text-mediumGrey);
        @media (max-width: $phone-md-max) {
          font-size: 12px;
        }
      }

      .image {
        @media (max-width: $phone-md-max) {
          width: 140px;
          height: 105px;
        }
      }

      .check-icon {
        circle:first-child {
          stroke: var(--colors-highlight)
        }

        circle:last-child {
          fill: var(--colors-highlight)
        }
      }
    }
  }
}