@import "../assets/variables";

.question-container {
  height: 100%;
  width: 60%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  margin: 64px 0px;

  @media screen and (max-height: 900px) {
    margin: 54px 0px 34px 0px;
  }

  @media (max-width: $phone-md-max) {
    width: 95%;
  }

  .questions {
    width: 100%;
    // padding-top: 16px;
  }

  .question {
    flex-grow: 1;
    margin-top: 64px;

    @media screen and (max-height: 900px) {
      margin-top: 54px;
    }

    @media (max-width: $phone-md-max) {
      // margin-top: 20px;
      padding: 0 15px;
    }
    .question-number.large {
      left: -35px;
    }

    .subtitle {
      font-weight: normal;
      font-size: 15px;
      line-height: 18px;
    }
  }

  .question:first-child {
    margin-top: 0;
  }

  .question:has(.section-container) + .question {
    margin-top: 20px;
  }
}

// some global styles, defined here because app.css is not sass
.button-highlight {
  background-color: var(--colors-highlight) !important;
  color: var(--colors-fontLight) !important;
}
.button-highlight.Mui-disabled {
  opacity: 0.5;
}
