@import '../../assets/variables';

.user-satisfaction-question-container {
  text-align: left;

  .options {
    display: flex;
    flex-wrap: wrap;
    width: 170%;
    margin-left: -35%;
    justify-content: center;

    @media (max-width: $phone-md-max) {
      width: 100%;
      margin-left: 0;
      justify-content: center;
    }

    .image-option {
      position: relative;
      display: flex;
      flex-direction: column;
      margin: 5px;
      width: 100px;
      box-shadow: 0 5px 6px rgba(0, 0, 0, 0.08), 0 2px 3px rgba(0, 0, 0, 0.24), 0 2px 1px rgba(0, 0, 0, 0.2);
      border-radius: 4px;
      padding-top: 5px;
      cursor: pointer;
      align-items: center;

      @media (max-width: $phone-md-max) {
        width: 140px;
      }

      @media (min-width: $screen-md-max) {
        width: 120px;
      }

      .image-text {
        font-size: 14px;
        flex-grow: 1;
        padding: 5px;
        font-style: normal;
        font-weight: bold;
        line-height: 16px;
        text-align: center;
        text-transform: uppercase;
        color: var(--colors-backgroundMedium);
      }

      .image {
        position: relative;
        width: 100px;
        height: auto;
        cursor: pointer;

        @media (max-width: $phone-md-max) {
          width: 140px;
          height: 105px;
        }
      }

      .user-satisfaction-icon {
        fill: var(--colors-highlight);

        path {
          fill: var(--colors-highlight);
        }
      }

      .check-icon {
        position: absolute;
        width: 32px;
        height: 32px;
        border-radius: 16px;
        color: var(--colors-backgroundLight);
      }
    }
    .image-option.checked {
      background-color: var(--colors-lightHighlight);
    }
  }
}
