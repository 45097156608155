@import '../../assets/variables';

.section-container {
  // section will be centered on screen
  margin: auto;
  display: flex;
  flex-direction: column;
  color: var(--colors-fontDark);
  white-space: pre-line;
  // margin-bottom: -24px;

  // fix width because of media content, todo try to make it work with relative width
  @media (max-width: $phone-md-max) {
    max-width: 360px;
  }

  .section-header {
    font-weight: 700;
    font-size: 28px;
    line-height: 28px;
    width: 100%;
  }

  .section-description {
    
    p{
      margin: 0;
      font-size: 15px;
      line-height: 20px;
      color: var(--text-lightGrey);
    }

    li{
      color: var(--text-lightGrey);
    }
  }

}
