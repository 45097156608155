@import '../../assets/variables';

.footer {
  width: 100%;
  height: 45px;
  background: var(--colors-main);
  display: flex;
  justify-content: center;
  padding-bottom: 3px;
}
