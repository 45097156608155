@import '../../assets/variables';

.multi-select-question-container {
  text-align: left;

  .options {
    display: block;
  }

  .checked-icon rect {
    fill: var(--colors-highlight);
    stroke: var(--colors-highlight);
  }

  .custom-item {
    display: flex;
    align-items: center;
    gap: 6px;
  }

  .MuiChip-root {
    background-color: var(--colors-highlight);
    color: var(--colors-fontLight);
    font-size: 13px;
    line-height: 24px;
    transition: unset;
  }

  .MuiChip-outlined {
    background-color: transparent;
    border-color: var(--colors-highlight);
    color: var(--colors-highlight);
  }

  .MuiChip-outlined:hover,
  .MuiChip-outlined:focus {
    background-color: transparent;
  }

  .MuiChip-root:hover,
  .MuiChip-root:focus {
    background-color: var(--colors-highlight);
  }

  .text-buttons {
    .MuiInputBase-root {
      color: inherit;
    }

    .text-button.custom {
      margin-right: 10px;

      .MuiChip-label {
        height: 100%;

        .MuiInputBase-root {
          color: inherit;
          margin: 5px 0 13px 0;
        }

        .MuiFormControl-root {
          text-transform: none;
        }

        .MuiInputLabel-root {
          top: -13px;
        }

        .MuiInputLabel-root.Mui-focused {
          top: 0;
        }
      }
    }
  }

  .images {
    display: flex;
    flex-wrap: wrap;
    // width: 150%;
    // margin-left: -25%;
    justify-content: left;

    @media (max-width: $phone-md-max) {
      width: 100%;
      margin-left: 0;

      // horizontal scroll
      display: flex;
      flex-wrap: nowrap;
      overflow-x: auto;
      justify-content: unset;
    }

    .image-option {
      display: flex;
      flex-direction: column;
      width: 300px;
      border-radius: 4px;
      position: relative;

      &:hover {
        box-shadow: 0px 2px 6px 0px #7B7D8947;
      }

      @media (max-width: $phone-md-max) {
        // horizontal scroll
        flex: 0 0 auto;
      }

      .image-text {
        font-size: 14px;
        flex-grow: 1;
        padding: 5px;
        color: var(--text-mediumGrey);

        @media (max-width: $phone-md-max) {
          font-size: 12px;
        }
      }

      .image {
        cursor: pointer;
        border-radius: 4px 4px 0 0;
        width: 100%;
        height: auto;
      }

      .check-icon {
        position: absolute;
        bottom: 7.5px;
        left: 7.5px;
        width: 18px;
        height: 18px;

        rect {
          fill: var(--colors-highlight);
        }
      }

      .extra-info-container {
        margin: 0;

        .extra-info-header {
          min-height: unset;

          .MuiAccordionSummary-content {
            margin: 0;

            .label {
              font-weight: bold;
              font-size: 16px;
              line-height: 19px;
              text-transform: uppercase;
              color: var(--colors-fontDark);
            }
          }
        }

        .extra-info-content-container {
          padding: 0;

          .extra-info-content {
            width: 100%;
            height: auto;
          }
        }
      }
    }

    .image-option.expandable {
      box-shadow: none;
    }
  }

  .remove-icon {
    cursor: pointer;
    color: gray;
  }

  .add-item {
    margin: 10px 0;
  }
}