@import '../../assets/variables';

.search-choose-question-container {
  text-align: left;

  .autocomplete {
    width: 300px;
    @media (max-width: $phone-md-max) {
      width: 200px;
    }
  }

  // check if hr has a sibling div.text-buttons

  hr + div.text-buttons {
    margin-top: 20px;
  }

  .add-contact-button {
    margin-bottom: 20px;
  }

  .MuiChip-root {
    background-color: var(--colors-highlight);
    color: var(--colors-fontLight);
    font-size: 13px;
    line-height: 24px;
    transition: unset;
  }

  .MuiChip-root:hover, .MuiChip-root:focus {
    background-color: var(--colors-highlight);
  }
}

.dialog {
  text-align: center;

  .MuiPaper-root {
    overflow-y: unset;
  }

  .new-item-form {
    padding: 30px 0;
    width: 350px;
  }
}
