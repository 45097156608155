@import '../assets/variables';

.graph {
  font-family: 'Open Sans', sans-serif;
  font-size: 11px;
  font-weight: 300;
  fill: #242424;
  text-align: center;
  text-shadow: 0 1px 0 #fff, 1px 0 0 #fff, -1px 0 0 #fff, 0 -1px 0 #fff;
  cursor: default;

  .legend {
    font-family: 'Raleway', sans-serif;
    fill: #333333;
  }

  .tooltip {
    fill: #333333;
  }

  .user-data {
    stroke: white;
  }

  .radar-text {
    text-shadow: none;
    font-weight: 700;
  }
}
